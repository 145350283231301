import React, { useState, useEffect } from 'react';
import BANKS from './bankRates';

const CALCULATION_TYPES = {
  SAVINGS: 'savings',
  FD: 'fd'
};

const SORT_TYPES = {
  NAME: 'name',
  RATE: 'rate'
};

const META_INFO = {
  title: "Bank Interest Calculator - Compare Savings & FD Rates",
  description: "Compare savings account and fixed deposit interest rates across major Indian banks. Find the best rates for your money with detailed tenure options and slab-wise calculations."
};

// Pure functions for calculations
const calculateSimpleInterest = (amount, ratePA, days) => {
  const interest = amount * (ratePA/100) * (days/365);
  const effectiveRate = (interest/amount) * (365/days) * 100;
  return { interest, effectiveRate, maturityAmount: amount + interest };
};

const calculateCompoundInterest = (amount, ratePA, days) => {
  const yearsFloat = days/365;
  const periodsPerYear = 4; // Quarterly compounding
  const totalPeriods = yearsFloat * periodsPerYear;
  
  const maturityAmount = amount * Math.pow(1 + (ratePA/(100 * periodsPerYear)), totalPeriods);
  const interest = maturityAmount - amount;
  const effectiveRate = ((maturityAmount/amount) ** (365/days) - 1) * 100;

  return { interest, effectiveRate, maturityAmount };
};

const calculateActualReturns = (amount, ratePA, days, type) => {
  if (type === CALCULATION_TYPES.SAVINGS) {
    const interest = amount * (ratePA/100);
    return { interest, effectiveRate: ratePA, maturityAmount: amount + interest };
  }

  if (days < 181 || days === 365) {
    return calculateSimpleInterest(amount, ratePA, days);
  }

  return calculateCompoundInterest(amount, ratePA, days);
};

// Utility functions
const calculateBankResults = (amount, days, calculationType) => {
  const results = {};
  
  Object.entries(BANKS).forEach(([bankName, bank]) => {
    const rawResult = calculationType === CALCULATION_TYPES.SAVINGS 
      ? bank.savings(amount)
      : bank.fd(amount, days);
      
    results[bankName] = typeof rawResult === 'string' 
      ? rawResult 
      : {
          nominal: rawResult,
          effective: calculateActualReturns(
            amount,
            (rawResult/amount) * 100,
            days,
            calculationType
          )
        };
  });

  return results;
};

const getSortedResults = (results, sortBy, calculationType, amount) => {
  return Object.entries(results).sort((a, b) => {
    if (sortBy === SORT_TYPES.NAME) {
      return a[0].localeCompare(b[0]);
    }
    
    const getRate = (result) => {
      if (typeof result === 'string') return -1;
      return calculationType === CALCULATION_TYPES.FD 
        ? result.effective.effectiveRate 
        : (result.nominal / amount * 100);
    };

    return getRate(b[1]) - getRate(a[1]);
  });
};

// React Components
const InputSection = ({ calculationType, inputAmount, setInputAmount, tenure, setTenure }) => (
  <div style={{ marginBottom: '1rem' }}>
    <div style={{ marginBottom: '1rem' }}>
      <label htmlFor="amount" style={{ display: 'block', marginBottom: '0.5rem' }}>
        {calculationType === CALCULATION_TYPES.SAVINGS ? 'Average Quarterly Balance' : 'Amount'}
      </label>
      <input
        id="amount"
        type="number"
        value={inputAmount}
        onChange={(e) => setInputAmount(e.target.value)}
        placeholder="Enter amount"
        style={{ width: '100%', padding: '0.5rem', borderRadius: '4px', border: '1px solid #ccc' }}
      />
    </div>
    {calculationType === CALCULATION_TYPES.FD && (
      <div style={{ marginBottom: '1rem' }}>
        <label htmlFor="tenure" style={{ display: 'block', marginBottom: '0.5rem' }}>
          Term in Days
        </label>
        <input
          id="tenure"
          type="number"
          value={tenure}
          onChange={(e) => setTenure(e.target.value)}
          placeholder="Enter tenure (days)"
          min="7"
          style={{ width: '100%', padding: '0.5rem', borderRadius: '4px', border: '1px solid #ccc' }}
        />
      </div>
    )}
  </div>
);

const ResultCard = ({ bank, result, calculationType, calculatedAmount }) => (
  <div style={{ border: '1px solid #ccc', borderRadius: '4px', padding: '1rem' }}>
    <h2 style={{ fontSize: '1.25rem', marginBottom: '0.5rem' }}>{bank}</h2>
    {typeof result === 'string' ? (
      <p style={{ fontSize: '1rem' }}>Interest: {result}</p>
    ) : (
      <>
        <p style={{ fontSize: '1rem' }}>
          Interest: ₹{calculationType === CALCULATION_TYPES.FD ? 
            result.effective.interest.toFixed(2) : 
            result.nominal.toFixed(2)}
        </p>
        {calculationType === CALCULATION_TYPES.FD ? (
          <>
            <p style={{ fontSize: '1rem' }}>
              Nominal Rate: {(result.nominal / calculatedAmount * 100).toFixed(2)}%
            </p>
            <p style={{ fontSize: '1rem' }}>
              Effective Yield: {result.effective.effectiveRate.toFixed(2)}%
            </p>
            <p style={{ fontSize: '1rem' }}>
              Maturity Amount: ₹{result.effective.maturityAmount.toFixed(2)}
            </p>
          </>
        ) : (
          <p style={{ fontSize: '1rem' }}>
            Rate: {(result.nominal / calculatedAmount * 100).toFixed(2)}%
          </p>
        )}
      </>
    )}
    <p style={{ fontSize: '0.75rem', marginTop: '0.5rem', color: '#666' }}>
      {calculationType === CALCULATION_TYPES.SAVINGS ? 
        BANKS[bank].savingsDescription : 
        BANKS[bank].fdDescription}
    </p>
  </div>
);

// Main Component
const InterestCalculator = () => {
  const [inputAmount, setInputAmount] = useState('');
  const [calculatedAmount, setCalculatedAmount] = useState(null);
  const [results, setResults] = useState({});
  const [sortBy, setSortBy] = useState(SORT_TYPES.NAME);
  const [calculationType, setCalculationType] = useState(CALCULATION_TYPES.SAVINGS);
  const [tenure, setTenure] = useState('365');

  useEffect(() => {
    document.title = META_INFO.title;
    
    const updateMetaTag = (name, content) => {
      let tag = document.querySelector(`meta[${name}]`);
      if (!tag) {
        tag = document.createElement('meta');
        tag.setAttribute(name.split('=')[0], name.split('=')[1].replace(/"/g, ''));
        document.head.appendChild(tag);
      }
      tag.content = content;
    };

    updateMetaTag('name="description"', META_INFO.description);
    updateMetaTag('property="og:title"', META_INFO.title);
    updateMetaTag('property="og:description"', META_INFO.description);

    return () => {
      document.title = "longloop's homepage";
    };
  }, []);

  const handleCalculate = () => {
    const amountValue = parseFloat(inputAmount);
    const tenureDays = parseInt(tenure);

    if (isNaN(amountValue) || amountValue <= 0) {
      alert('Please enter a valid positive number');
      return;
    }

    if (calculationType === CALCULATION_TYPES.FD && (isNaN(tenureDays) || tenureDays < 7)) {
      alert('Please enter a valid tenure (minimum 7 days)');
      return;
    }

    setCalculatedAmount(amountValue);
    setResults(calculateBankResults(amountValue, tenureDays, calculationType));
  };

  const sortedResults = calculatedAmount ? 
    getSortedResults(results, sortBy, calculationType, calculatedAmount) : 
    [];

  return (
    <div style={{ padding: '1rem', maxWidth: '800px', margin: '0 auto' }}>
      <h1 style={{ fontSize: '1.5rem', fontWeight: 'bold', marginBottom: '1rem' }}>Interest Calculator</h1>
      <p style={{ fontSize: '0.875rem', marginBottom: '1rem', fontStyle: 'italic' }}>
        Information is based on the best available data as of 24th September 2024.
      </p>

      <div style={{ marginBottom: '1rem' }}>
        <div style={{ marginBottom: '1rem', display: 'flex', gap: '1rem' }}>
          {Object.values(CALCULATION_TYPES).map(type => (
            <label key={type} style={{ display: 'flex', alignItems: 'center' }}>
              <input
                type="radio"
                value={type}
                checked={calculationType === type}
                onChange={(e) => setCalculationType(e.target.value)}
                style={{ marginRight: '0.5rem' }}
              />
              {type === CALCULATION_TYPES.SAVINGS ? 'Savings Account' : 'Fixed Deposit'}
            </label>
          ))}
        </div>

        <InputSection
          calculationType={calculationType}
          inputAmount={inputAmount}
          setInputAmount={setInputAmount}
          tenure={tenure}
          setTenure={setTenure}
        />
      </div>

      <div style={{ marginBottom: '1rem', display: 'flex', gap: '1rem' }}>
        <button
          onClick={handleCalculate}
          style={{
            padding: '0.5rem 1rem',
            backgroundColor: '#0066cc',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer'
          }}
        >
          Calculate
        </button>
        {calculatedAmount !== null && (
          <select
            value={sortBy}
            onChange={(e) => setSortBy(e.target.value)}
            style={{
              padding: '0.5rem',
              borderRadius: '4px',
              border: '1px solid #ccc'
            }}
          >
            <option value={SORT_TYPES.NAME}>Sort by Name</option>
            <option value={SORT_TYPES.RATE}>Sort by Rate</option>
          </select>
        )}
      </div>

      {calculatedAmount !== null && (
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))', gap: '1rem' }}>
          {sortedResults.map(([bank, result]) => (
            <ResultCard
              key={bank}
              bank={bank}
              result={result}
              calculationType={calculationType}
              calculatedAmount={calculatedAmount}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default InterestCalculator;