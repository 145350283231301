import React, { useState, useCallback } from 'react';
import investmentJson from './investmentData.json'
import investmentMetric from './investmentMetric.json'

/* TODO List unordered
- implement ups and downs within range in market related invetnments to calculate return by varying rate each year
- introduce high water mark in AIF
- add better (correct) interest and tax numbers for each investment
*/

const calculatePerformanceFee = (returns, performanceFee, performanceTarget) => {
  if (returns > performanceTarget && performanceFee) {
    const excessReturn = returns - performanceTarget;
    return excessReturn * performanceFee;
  }
  return 0;
};

const calculateReturns = (principal, years, data, taxSlab, applyExemptions) => {
  if (!data || typeof data.Returns !== 'number') {
    return null;
  }

  const returns = data.Returns;
  const chargesRate = data.Charges || 0;
  const performanceFee = data.performanceFee || 0;
  const performanceTarget = data.performanceTarget || 0;

  let initialPrincipal = principal;
  if (applyExemptions) {
    if (data.TaxExemptContribution) {
      let exemptLimit = 150000;
      initialPrincipal = (principal >= exemptLimit) ?
        (principal - exemptLimit) * (1 - taxSlab / 100) + exemptLimit :
        principal;
    } else {
      initialPrincipal = principal * (1 - taxSlab / 100);
    }
  }

  // Compound interest calculation with yearly charges and performance fee
  let amount = initialPrincipal;
  let totalCharges = 0;
  let totalPerformanceFees = 0;
  for (let i = 0; i < years; i++) {
    // First, apply returns
    const yearlyReturn = amount * returns;
    amount += yearlyReturn;

    // Calculate and apply performance fee
    const yearlyPerformanceFee = calculatePerformanceFee(returns, performanceFee, performanceTarget) * yearlyReturn;
    totalPerformanceFees += yearlyPerformanceFee;
    amount -= yearlyPerformanceFee;

    // Then, calculate and apply charges
    let yearlyCharge = amount * chargesRate;
    totalCharges += yearlyCharge;
    amount -= yearlyCharge;
  }

  const interest = amount - initialPrincipal;

  let tax = 0;

  if (!data.TaxExemptInterest && !data.TaxExemptReturns) {
    if (data.StcgLtcgMix) {
      // Assuming 65% LTCG and 35% STCG ratio
      const ltcgInterest = interest * 0.65;
      const stcgInterest = interest * 0.35;

      if (years > 1) {
        // Apply LTCG tax with ₹1 lakh exemption
        const taxableLtcgInterest = Math.max(ltcgInterest - 100000, 0);
        tax += taxableLtcgInterest * data.LTCG;

        // Apply STCG tax
        tax += stcgInterest * (data.STCG === "slab" ? taxSlab / 100 : data.STCG);
      } else {
        // If held for 1 year or less, treat all as STCG
        tax = interest * (data.STCG === "slab" ? taxSlab / 100 : data.STCG);
      }
    } else {
      if (data.LTCG && years > 1) {
        // Long-term capital gains
        const taxableInterest = Math.max(interest - 100000, 0); // Assuming ₹1 lakh exemption
        tax = taxableInterest * data.LTCG;
      } else if (data.STCG) {
        // Short-term capital gains
        tax = interest * (data.STCG === "slab" ? taxSlab / 100 : data.STCG);
      } else if (data.TaxableInterest) {
        // Regular taxation
        tax = interest * (taxSlab / 100);
      } else if (data.Taxation === "Pass-through" || data.Taxation === "As per underlying securities") {
        // Simplified assumption for complex structures
        tax = interest * (taxSlab / 100);
      } else if (data.Taxation === "Fund level") {
        tax = interest * (data.taxRate || 0.30); // Use specific tax rate if available, otherwise default to 30%
      } else {
        // Default case: tax at slab rate
        tax = interest * (taxSlab / 100);
      }
    }
  }

  const finalValue = amount - tax;
  return {
    interest: parseFloat(interest.toFixed(2)),
    tax: parseFloat(tax.toFixed(2)),
    charges: parseFloat(totalCharges.toFixed(2)),
    performanceFees: parseFloat(totalPerformanceFees.toFixed(2)),
    finalValue: parseFloat(finalValue.toFixed(2)),
    interestRate: returns,
    chargesRate: chargesRate,
    stcgLtcgMix: data.StcgLtcgMix,
    performanceFee: performanceFee,
    performanceTarget: performanceTarget
  };
};

const calculateAllReturns = (principal, years, taxSlab, data, applyExemptions, parentKey = '') => {
  let results = {};

  Object.entries(data).forEach(([key, value]) => {
    const fullKey = parentKey ? `${parentKey} - ${key}` : key;

    if (typeof value === 'object' && value !== null && !('Returns' in value)) {
      // This is a category with subcategories
      const subResults = calculateAllReturns(principal, years, taxSlab, value, applyExemptions, fullKey);
      results = { ...results, ...subResults };
    } else {
      // This is a leaf node (actual investment type)
      const result = calculateReturns(principal, years, value, taxSlab, applyExemptions);
      if (result) {
        results[fullKey] = result;
      }
    }
  });

  return results;
};

const InvestmentCalculator = () => {
  const [principal, setPrincipal] = useState('');
  const [years, setYears] = useState('');
  const [taxSlab, setTaxSlab] = useState('');
  const [results, setResults] = useState({});
  const [sortOrder, setSortOrder] = useState(null);
  const [viewMode, setViewMode] = useState('default');
  const [applyExemptions, setApplyExemptions] = useState(false);
  const [expandedItems, setExpandedItems] = useState({});
  const [expandedCategories, setExpandedCategories] = useState({});

  const handleCalculate = useCallback(() => {
    const p = parseFloat(principal);
    const y = parseFloat(years);
    const ts = parseFloat(taxSlab);
    if (isNaN(p) || isNaN(y) || isNaN(ts) || p <= 0 || y <= 0 || ts < 0 || ts > 100) {
      alert('Please enter valid positive numbers for principal, years, and tax slab (0-100).');
      return;
    }

    const newResults = calculateAllReturns(p, y, ts, investmentMetric, applyExemptions);
    setResults(newResults);
  }, [principal, years, taxSlab, applyExemptions]);

  const toggleSort = useCallback(() => {
    setSortOrder(prevOrder => {
      if (prevOrder === 'asc') return 'desc';
      if (prevOrder === 'desc') return null;
      return 'asc';
    });
    setViewMode('sorted');
  }, []);

  const setDefaultView = useCallback(() => {
    setViewMode('default');
    setSortOrder(null);
  }, []);

  const toggleExpand = (key) => {
    setExpandedItems(prev => ({ ...prev, [key]: !prev[key] }));
  };

  const toggleCategoryExpand = (category) => {
    setExpandedCategories(prev => ({ ...prev, [category]: !prev[category] }));
  };

  const groupResultsByCategory = (results) => {
    const grouped = {};
    Object.entries(results).forEach(([key, value]) => {
      const [category, ...rest] = key.split(' - ');
      if (!grouped[category]) {
        grouped[category] = {};
      }
      grouped[category][rest.join(' - ')] = value;
    });
    return grouped;
  };

  const sortResults = (results) => {
    if (!sortOrder) return results;

    return Object.fromEntries(
      Object.entries(results).sort(([, a], [, b]) =>
        sortOrder === 'desc' ? b.finalValue - a.finalValue : a.finalValue - b.finalValue
      )
    );
  };

  const renderResults = () => {
    const sortedResults = sortResults(results);

    if (viewMode === 'default') {
      const groupedResults = groupResultsByCategory(sortedResults);
      return Object.entries(groupedResults).map(([category, items]) => (
        <div key={category} style={{ marginBottom: '20px', border: '1px solid #ddd', borderRadius: '5px' }}>
          <div
            onClick={() => toggleCategoryExpand(category)}
            style={{
              padding: '10px',
              backgroundColor: '#f0f0f0',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <span style={{ marginRight: '10px', fontSize: '20px' }}>
              {expandedCategories[category] ? '▼' : '▶'}
            </span>
            <h2 style={{ margin: '0', fontSize: '18px' }}>{category}</h2>
          </div>
          {expandedCategories[category] && (
            <div style={{ padding: '10px' }}>
              {Object.entries(items).map(([key, value]) => renderResultItem(category, key, value))}
            </div>
          )}
        </div>
      ));
    } else {
      return Object.entries(sortedResults).map(([key, value]) => {
        const [category, ...rest] = key.split(' - ');
        return renderResultItem(category, rest.join(' - '), value);
      });
    }
  };

  const renderResultItem = (category, key, value) => {
    const fullKey = `${category} - ${key}`;
    const investmentDetails = getInvestmentDetails(fullKey);
    const originalData = getOriginalInvestmentData(fullKey);

    return (
      <div key={fullKey} style={{ marginBottom: '20px', border: '1px solid #ccc', padding: '15px' }}>
        <h3 style={{ fontSize: '16px', marginBottom: '10px' }}>{fullKey}</h3>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
          <div>
            <label>
              Interest Rate (%):
              <input
                type="text"
                value={(value.interestRate * 100).toFixed(2)}
                onChange={(e) => handleInterestRateChange(fullKey, e.target.value)}
                style={{ width: '60px', marginLeft: '5px' }}
              />
            </label>
          </div>
          <div>
            <label>
              Charges (%):
              <input
                type="text"
                value={(value.chargesRate * 100).toFixed(2)}
                onChange={(e) => handleChargesChange(fullKey, e.target.value)}
                style={{ width: '60px', marginLeft: '5px' }}
              />
            </label>
          </div>
          {originalData.performanceFee !== undefined && (
            <div>
              <label>
                Performance Fee (%):
                <input
                  type="text"
                  value={(value.performanceFee * 100).toFixed(2)}
                  onChange={(e) => handlePerformanceFeeChange(fullKey, e.target.value)}
                  style={{ width: '60px', marginLeft: '5px' }}
                />
              </label>
            </div>
          )}
          {originalData.performanceTarget !== undefined && (
            <div>
              <label>
                Performance Target (%):
                <input
                  type="text"
                  value={(value.performanceTarget * 100).toFixed(2)}
                  onChange={(e) => handlePerformanceTargetChange(fullKey, e.target.value)}
                  style={{ width: '60px', marginLeft: '5px' }}
                />
              </label>
            </div>
          )}
          <button
            onClick={() => handleRecalculate(fullKey)}
            style={{ padding: '5px 10px', backgroundColor: '#17a2b8', color: 'white', border: 'none', cursor: 'pointer' }}
          >
            Recalculate
          </button>
        </div>
        <p>Interest Earned: ₹{value.interest.toLocaleString('en-IN')}</p>
        <p>Tax Payable: ₹{value.tax.toLocaleString('en-IN')}</p>
        <p>Charges: ₹{value.charges.toLocaleString('en-IN')}</p>
        {value.performanceFees > 0 && (
          <p>Performance Fees: ₹{value.performanceFees.toLocaleString('en-IN')}</p>
        )}
        <p><strong>Final Value: ₹{value.finalValue.toLocaleString('en-IN')}</strong></p>
        {value.stcgLtcgMix && (
          <p style={{ fontStyle: 'italic', color: '#666' }}>
            (Assuming 65% LTCG / 35% STCG ratio for tax calculation)
          </p>
        )}
        {value.performanceFee > 0 && (
          <p style={{ fontStyle: 'italic', color: '#666' }}>
            (Performance Fee: {(value.performanceFee * 100).toFixed(2)}% above {(value.performanceTarget * 100).toFixed(2)}% return)
          </p>
        )}
        <div style={{ marginTop: '10px', backgroundColor: '#f8f9fa', padding: '10px', borderRadius: '5px' }}>
          <p><strong>Taxation:</strong> {renderTaxationInfo(originalData)}</p>
          <p><strong>Charges and Expenses:</strong> {renderValue(investmentDetails['Charges and Expenses']) || 'Information not available'}</p>
        </div>

        <button
          onClick={() => toggleExpand(fullKey)}
          style={{ marginTop: '10px', padding: '5px 10px', backgroundColor: '#17a2b8', color: 'white', border: 'none', cursor: 'pointer' }}
        >
          {expandedItems[fullKey] ? 'Less' : 'More'}
        </button>

        {expandedItems[fullKey] && (
          <div style={{ marginTop: '10px', backgroundColor: '#f8f9fa', padding: '10px', borderRadius: '5px' }}>
            {Object.entries(investmentDetails).map(([detailKey, detailValue]) => (
              <p key={detailKey}><strong>{detailKey}:</strong> {renderValue(detailValue)}</p>
            ))}
          </div>
        )}
      </div>
    );
  };

  const handlePerformanceFeeChange = (key, newFee) => {
    const fee = parseFloat(newFee);
    if (!isNaN(fee)) {
      setResults(prevResults => ({
        ...prevResults,
        [key]: { ...prevResults[key], performanceFee: fee / 100 }
      }));
    }
  };
  const renderTaxationInfo = (data) => {
    if (data.Taxation === "Fund level" && data.taxRate) {
      return `Fund level taxation at ${(data.taxRate * 100).toFixed(2)}%`;
    }
    return renderValue(data.Taxation) || 'Information not available';
  };
  
  const handlePerformanceTargetChange = (key, newTarget) => {
    const target = parseFloat(newTarget);
    if (!isNaN(target)) {
      setResults(prevResults => ({
        ...prevResults,
        [key]: { ...prevResults[key], performanceTarget: target / 100 }
      }));
    }
  };

  const handleInterestRateChange = (key, newRate) => {
    const rate = parseFloat(newRate);
    if (!isNaN(rate)) {
      setResults(prevResults => ({
        ...prevResults,
        [key]: { ...prevResults[key], interestRate: rate / 100 }
      }));
    }
  };

  const handleChargesChange = (key, newCharges) => {
    const charges = parseFloat(newCharges);
    if (!isNaN(charges)) {
      setResults(prevResults => ({
        ...prevResults,
        [key]: { ...prevResults[key], chargesRate: charges / 100 }
      }));
    }
  };

  const getOriginalInvestmentData = (key) => {
    const parts = key.split(' - ');
    let data = investmentMetric;
    for (let part of parts) {
      if (data[part]) {
        data = data[part];
      } else {
        return {};
      }
    }
    return data;
  };
  
  const handleRecalculate = (key) => {
    const originalData = getOriginalInvestmentData(key);
    const updatedData = {
      ...originalData,
      Returns: results[key].interestRate,
      Charges: results[key].chargesRate,
    };
  
    if (originalData.performanceFee !== undefined) {
      updatedData.performanceFee = results[key].performanceFee;
    }
    if (originalData.performanceTarget !== undefined) {
      updatedData.performanceTarget = results[key].performanceTarget;
    }
  
    const updatedResult = calculateReturns(
      parseFloat(principal),
      parseFloat(years),
      updatedData,
      parseFloat(taxSlab),
      applyExemptions
    );
    setResults(prevResults => ({
      ...prevResults,
      [key]: updatedResult
    }));
  };
  

  const getInvestmentDetails = (key) => {
    const parts = key.split(' - ');
    let details = investmentJson;
    if (!details[parts[0]].hasOwnProperty(`subCategories`))
      return details[parts[0]]
    for (let part of parts) {
      if (details.subCategories) {
        details = details.subCategories[part] || {};
      } else {
        details = details[part] || {};
      }
    }
    return details;
  };

  const renderValue = (value) => {
    if (typeof value === 'object' && value !== null) {
      return JSON.stringify(value);
    }
    return value;
  };

  return (
    <div style={{ maxWidth: '800px', margin: '0 auto', padding: '20px' }}>
      <h1 style={{ fontSize: '24px', marginBottom: '20px' }}>Comprehensive Investment Calculator</h1>

      <div style={{
        backgroundColor: '#fff3cd',
        color: '#856404',
        padding: '15px',
        borderRadius: '5px',
        marginBottom: '20px',
        border: '1px solid #ffeeba'
      }}>
        <strong>Disclaimer:</strong> This is an alpha version of the investment calculator.
        The calculations and logic may contain errors or inaccuracies.
        Use this tool at your own risk. The information provided here is not financial advice.
        Always consult with a qualified financial advisor before making investment decisions.
      </div>

      <div style={{ marginBottom: '20px' }}>
        <label style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
          <input
            type="checkbox"
            checked={applyExemptions}
            onChange={(e) => setApplyExemptions(e.target.checked)}
            style={{ marginRight: '8px' }}
          />
          Calculate for pre-tax income (enable exemption for 80c where applicable and apply slab tax on amount for others)
        </label>
      </div>

      <div style={{ marginBottom: '20px' }}>
        <label style={{ display: 'block', marginBottom: '5px' }}>
          Principal Amount (₹):
          <input
            type="number"
            value={principal}
            onChange={(e) => setPrincipal(e.target.value)}
            style={{ width: '100%', padding: '5px' }}
          />
        </label>
      </div>
      <div style={{ marginBottom: '20px' }}>
        <label style={{ display: 'block', marginBottom: '5px' }}>
          Investment Duration (Years):
          <input
            type="number"
            value={years}
            onChange={(e) => setYears(e.target.value)}
            style={{ width: '100%', padding: '5px' }}
          />
        </label>
      </div>
      <div style={{ marginBottom: '20px' }}>
        <label style={{ display: 'block', marginBottom: '5px' }}>
          Your Tax Slab (%):
          <input
            type="number"
            value={taxSlab}
            onChange={(e) => setTaxSlab(e.target.value)}
            style={{ width: '100%', padding: '5px' }}
          />
        </label>
      </div>
      <button
        onClick={handleCalculate}
        style={{ padding: '10px 20px', backgroundColor: '#007bff', color: 'white', border: 'none', cursor: 'pointer', marginBottom: '20px' }}
      >
        Calculate All
      </button>

      {Object.keys(results).length > 0 && (
        <div style={{ marginTop: '20px' }}>
          <h2 style={{ fontSize: '20px', marginBottom: '10px' }}>Results</h2>
          <div style={{ display: 'flex', gap: '10px', marginBottom: '20px' }}>
            <button
              onClick={toggleSort}
              style={{ padding: '5px 10px', backgroundColor: '#28a745', color: 'white', border: 'none', cursor: 'pointer' }}
            >
              Sort {sortOrder === 'asc' ? '▲' : sortOrder === 'desc' ? '▼' : ''}
            </button>
            <button
              onClick={setDefaultView}
              style={{
                padding: '5px 10px',
                backgroundColor: viewMode === 'default' ? '#007bff' : '#6c757d',
                color: 'white',
                border: 'none',
                cursor: 'pointer'
              }}
            >
              Default View
            </button>
          </div>
          {renderResults()}
        </div>
      )}
    </div>
  );
};

export default InvestmentCalculator;
