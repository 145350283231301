const AU = {
    savings: (amount) => {
      if (amount <= 300000) return amount * 0.03;  // Up to 3L @ 3%
      if (amount <= 500000) return 300000 * 0.03 + (amount - 300000) * 0.04;  // 3L to 5L @ 4%
      if (amount <= 1000000) return 300000 * 0.03 + 200000 * 0.04 + (amount - 500000) * 0.05;  // 5L to 10L @ 5%
      if (amount <= 2500000) return 300000 * 0.03 + 200000 * 0.04 + 500000 * 0.05 + (amount - 1000000) * 0.07;  // 10L to 25L @ 7%
      if (amount <= 10000000) return 300000 * 0.03 + 200000 * 0.04 + 500000 * 0.05 + 1500000 * 0.07 + (amount - 2500000) * 0.07;  // 25L to 1Cr @ 7%
      if (amount <= 50000000) return 300000 * 0.03 + 200000 * 0.04 + 500000 * 0.05 + 1500000 * 0.07 + 7500000 * 0.07 + (amount - 10000000) * 0.0725;  // 1Cr to 5Cr @ 7.25%
      if (amount <= 100000000) return 300000 * 0.03 + 200000 * 0.04 + 500000 * 0.05 + 1500000 * 0.07 + 7500000 * 0.07 + 40000000 * 0.0725 + (amount - 50000000) * 0.07;  // 5Cr to 10Cr @ 7%
      return "Contact Branch";  // Above 10Cr
    },
    fd: (amount, days) => {
      // Return early if amount is >= 3 Crore
      if (amount >= 30000000) return "Contact Branch";
  
      // Define rate slabs based on days
      if (days <= 45) return amount * 0.0375;  // 7 days to 1 month 15 days @ 3.75%
      if (days <= 90) return amount * 0.055;   // 1 month 16 days to 3 months @ 5.50%
      if (days <= 180) return amount * 0.06;   // 3 months 1 day to 6 months @ 6.00%
      if (days <= 365) return amount * 0.0725; // 6 months 1 day to 12 months @ 7.25%
      if (days <= 456) return amount * 0.0785; // 12 months 1 day to 15 months @ 7.85%
      if (days <= 547) return amount * 0.075;  // 15 months 1 day to less than 18 months @ 7.50%
      if (days === 548) return amount * 0.08;  // 18 months exactly @ 8.00%
      if (days <= 730) return amount * 0.0775; // 18 months 1 day to 24 months @ 7.75%
      if (days <= 1095) return amount * 0.075; // 24 months 1 day to 36 months @ 7.50%
      if (days <= 1370) return amount * 0.075; // 36 months 1 day to 45 months @ 7.50%
      if (days <= 1825) return amount * 0.0725; // 45 months 1 day to less than 60 months @ 7.25%
      if (days <= 3650) return amount * 0.0725; // 60 months to 120 months @ 7.25%
  
      // Special rates for amounts >= 1 Crore
      if (amount >= 10000000) {
        if (days > 365 && days <= 456) return amount * 0.0795;  // 12 months 1 day to 15 months @ 7.95%
        if (days > 456 && days < 548) return amount * 0.076;    // 15 months 1 day to less than 18 months @ 7.60%
        if (days === 548) return amount * 0.081;                // 18 months exactly @ 8.10%
        if (days > 548 && days <= 730) return amount * 0.0785;  // 18 months 1 day to 24 months @ 7.85%
        if (days > 730 && days <= 1095) return amount * 0.076;  // 24 months 1 day to 36 months @ 7.60%
      }
  
      return "Invalid tenure";
    },
    savingsDescription: "Uses incremental slabs: 3% up to ₹3L, 4% from ₹3-5L, 5% from ₹5-10L, 7% from ₹10-25L, 7% from ₹25L-1Cr, 7.25% from ₹1-5Cr, 7% from ₹5-10Cr",
    fdDescription: "7-45d: 3.75%, 46-90d: 5.5%, 91-180d: 6%, 181-365d: 7.25%, 366-456d: 7.85%, 457-547d: 7.5%, 548d(18m): 8%, 549-730d: 7.75%, 731-1095d: 7.5%, 1096-1370d: 7.5%, 1371-1825d: 7.25%, 1826-3650d: 7.25%. Higher rates for ₹1Cr+",
  };
  
  const DBS = {
    savings: (amount) => {
      if (amount <= 100000) return amount * 0.0325;  // Up to 1L @ 3.25%
      if (amount <= 400000) return 100000 * 0.0325 + (amount - 100000) * 0.035;  // 1L to 4L @ 3.50%
      if (amount <= 500000) return 100000 * 0.0325 + 300000 * 0.035 + (amount - 400000) * 0.07;  // 4L to 5L @ 7.00%
      if (amount <= 1000000) return 100000 * 0.0325 + 300000 * 0.035 + 100000 * 0.07 + (amount - 500000) * 0.05;  // 5L to 10L @ 5.00%
      if (amount <= 5000000) return 100000 * 0.0325 + 300000 * 0.035 + 100000 * 0.07 + 500000 * 0.05 + (amount - 1000000) * 0.04;  // 10L to 50L @ 4.00%
      if (amount <= 10000000) return 100000 * 0.0325 + 300000 * 0.035 + 100000 * 0.07 + 500000 * 0.05 + 4000000 * 0.04 + (amount - 5000000) * 0.05;  // 50L to 1Cr @ 5.00%
      return 100000 * 0.0325 + 300000 * 0.035 + 100000 * 0.07 + 500000 * 0.05 + 4000000 * 0.04 + 5000000 * 0.05 + (amount - 10000000) * 0.03;  // Above 1Cr @ 3.00%
    },
    fd: (amount, days) => {
      if (amount >= 30000000) return "Contact Branch";  // 3 Crore or more
  
      if (days < 7) return "Invalid tenure";
      if (days <= 7) return amount * 0.025;            // 7 days @ 2.50%
      if (days <= 14) return amount * 0.0275;          // 8-14 days @ 2.75%
      if (days <= 29) return amount * 0.04;            // 15-29 days @ 4.00%
      if (days <= 45) return amount * 0.045;           // 30-45 days @ 4.50%
      if (days <= 60) return amount * 0.045;           // 46-60 days @ 4.50%
      if (days <= 90) return amount * 0.05;            // 61-90 days @ 5.00%
      if (days <= 180) return amount * 0.05;           // 91-180 days @ 5.00%
      if (days <= 269) return amount * 0.06;           // 181-269 days @ 6.00%
      if (days <= 364) return amount * 0.06;           // 270-364 days @ 6.00%
      if (days <= 375) return amount * 0.07;           // 1 year to 375 days @ 7.00%
      if (days <= 540) return amount * 0.075;          // 376-540 days @ 7.50%
      if (days <= 599) return amount * 0.0725;         // 541-599 days @ 7.25%
      if (days <= 600) return amount * 0.0725;         // 600 days @ 7.25%
      if (days <= 729) return amount * 0.0725;         // 601 days to < 2 years @ 7.25%
      if (days <= 912) return amount * 0.07;           // 2 years to < 2.5 years @ 7.00%
      if (days <= 913) return amount * 0.07;           // 2.5 years @ 7.00%
      if (days <= 1095) return amount * 0.07;          // 2.5 years + 1 day to < 3 years @ 7.00%
      if (days <= 1460) return amount * 0.065;         // 3 years to < 4 years @ 6.50%
      if (days <= 1825) return amount * 0.065;         // 4 years to < 5 years @ 6.50%
      return amount * 0.065;                           // 5 years and above @ 6.50%
    },
    fdDescription: "7d: 2.5%, 8-14d: 2.75%, 15-29d: 4%, 30-45d: 4.5%, 46-60d: 4.5%, 61-90d: 5%, 91-180d: 5%, 181-269d: 6%, 270d-<1y: 6%, 1y-375d: 7%, 376-540d: 7.5%, 541-599d: 7.25%, 600d-<2y: 7.25%, 2y-<3y: 7%, 3y+: 6.5%",
    savingsDescription: "Uses incremental slabs: 3.25% up to ₹1L, 3.5% from ₹1-4L, 7% from ₹4-5L, 5% from ₹5-10L, 4% from ₹10-50L, 5% from ₹50L-1Cr, 3% above ₹1Cr",
  };
  
  const Indusind = {
    savings: (amount) => {
      if (amount <= 100000) return amount * 0.03;
      if (amount <= 500000) return 100000 * 0.03 + (amount - 100000) * 0.05;
      if (amount <= 1000000) return 100000 * 0.03 + 400000 * 0.05 + (amount - 500000) * 0.06;
      if (amount <= 2500000) return 100000 * 0.03 + 400000 * 0.05 + 500000 * 0.06 + (amount - 1000000) * 0.0675;
      if (amount <= 50000000) return 100000 * 0.03 + 400000 * 0.05 + 500000 * 0.06 + 1500000 * 0.0675 + (amount - 2500000) * 0.0675;
      if (amount <= 500000000) return amount * 0.0675;
      if (amount <= 1000000000) return amount * 0.0675;
      return "Contact Branch";
    },
    fd: (amount, days) => {
      if (amount >= 30000000) return "Contact Branch";  // 3 Crore or more
  
      if (days < 7) return "Invalid tenure";
      if (days <= 14) return amount * 0.035;           // 7-14 days @ 3.50%
      if (days <= 30) return amount * 0.035;           // 15-30 days @ 3.50%
      if (days <= 45) return amount * 0.0375;          // 31-45 days @ 3.75%
      if (days <= 60) return amount * 0.0475;          // 46-60 days @ 4.75%
      if (days <= 90) return amount * 0.0475;          // 61-90 days @ 4.75%
      if (days <= 120) return amount * 0.0475;         // 91-120 days @ 4.75%
      if (days <= 180) return amount * 0.05;           // 121-180 days @ 5.00%
      if (days <= 210) return amount * 0.0585;         // 181-210 days @ 5.85%
      if (days <= 269) return amount * 0.061;          // 211-269 days @ 6.10%
      if (days <= 354) return amount * 0.0635;         // 270-354 days @ 6.35%
      if (days <= 364) return amount * 0.065;          // 355-364 days @ 6.50%
      if (days <= 455) return amount * 0.0775;         // 1 year to below 1 year 3 months @ 7.75%
      if (days <= 485) return amount * 0.0775;         // 15-16 months @ 7.75%
      if (days <= 547) return amount * 0.0775;         // 16-18 months @ 7.75%
      if (days <= 730) return amount * 0.0775;         // 18-24 months @ 7.75%
      if (days <= 912) return amount * 0.0725;         // 2-2.5 years @ 7.25%
      if (days <= 943) return amount * 0.0725;         // 30-31 months @ 7.25%
      if (days <= 1185) return amount * 0.0725;        // 31 months to 3 years 3 months @ 7.25%
      if (days <= 1825) return amount * 0.0725;        // 3 years 3 months to 61 months @ 7.25%
      return amount * 0.07;                            // 61 months and above @ 7.00%
    },
    savingsDescription: "Uses slab rates: 3% up to ₹1L, 5% from ₹1-5L, 6% from ₹5-10L, 6.75% above ₹10L",
    fdDescription: "7-30d: 3.5%, 31-45d: 3.75%, 46-120d: 4.75%, 121-180d: 5%, 181-210d: 5.85%, 211-269d: 6.1%, 270-354d: 6.35%, 355-364d: 6.5%, 1y-18m: 7.75%, 18m-2y: 7.75%, >2y-5y1m: 7.25%, >5y1m: 7%"
  };
  
  const Axis = {
    savings: (amount) => {
      if (amount < 5000000) return amount * 0.03;
      if (amount < 20000000000) return amount * 0.035;
      return "Contact Branch (MIBOR + 0.70%)";
    },
    fd: (amount, days) => {
      if (amount >= 50000000) return "Contact Branch";  // 5 Crore or more
  
      // Determine which rate column to use based on amount
      const isHighValue = amount >= 30000000 && amount < 50000000; // Between 3Cr and 5Cr
  
      if (days < 7) return "Invalid tenure";
      if (days <= 14) return amount * (isHighValue ? 0.0475 : 0.03);     // 7-14 days
      if (days <= 29) return amount * (isHighValue ? 0.0475 : 0.03);     // 15-29 days
      if (days <= 45) return amount * (isHighValue ? 0.055 : 0.035);     // 30-45 days
      if (days <= 60) return amount * (isHighValue ? 0.0575 : 0.0425);   // 46-60 days
      if (days <= 90) return amount * (isHighValue ? 0.06 : 0.045);      // 61-90 days
      if (days <= 114) return amount * (isHighValue ? 0.065 : 0.0475);   // 91-114 days
      if (days <= 120) return amount * (isHighValue ? 0.0515 : 0.0475);  // 115-120 days
      if (days <= 150) return amount * (isHighValue ? 0.065 : 0.0475);   // 121-150 days
      if (days <= 180) return amount * (isHighValue ? 0.065 : 0.0475);   // 151-180 days
      if (days <= 210) return amount * (isHighValue ? 0.0675 : 0.0575);  // 181-210 days
      if (days <= 240) return amount * (isHighValue ? 0.0675 : 0.0575);  // 211-240 days
      if (days <= 270) return amount * (isHighValue ? 0.0685 : 0.06);    // 241-270 days
      if (days <= 364) return amount * (isHighValue ? 0.0685 : 0.06);    // 271-364 days
      if (days <= 369) return amount * (isHighValue ? 0.073 : 0.067);    // 1 year – 1 year 4 days
      if (days <= 375) return amount * (isHighValue ? 0.073 : 0.067);    // 1 year 5 days – 1 year 10 days
      if (days <= 389) return amount * (isHighValue ? 0.073 : 0.067);    // 1 year 11 days – 1 year 24 days
      if (days <= 395) return amount * (isHighValue ? 0.0725 : 0.067);   // 1 year 25 days < 13 months
      if (days <= 425) return amount * (isHighValue ? 0.0725 : 0.067);   // 13 months < 14 months
      if (days <= 455) return amount * (isHighValue ? 0.0725 : 0.067);   // 14 months < 15 months
      if (days <= 485) return amount * (isHighValue ? 0.0705 : 0.0725);  // 15 months < 16 months
      if (days <= 515) return amount * (isHighValue ? 0.0705 : 0.0725);  // 16 months < 17 months
      if (days <= 545) return amount * (isHighValue ? 0.0705 : 0.0725);  // 17 months < 18 months
      if (days <= 730) return amount * (isHighValue ? 0.0705 : 0.0725);  // 18 Months < 2 years
      if (days <= 912) return amount * (isHighValue ? 0.07 : 0.071);     // 2 years < 30 months
      if (days <= 1095) return amount * (isHighValue ? 0.07 : 0.071);    // 30 months < 3 years
      if (days <= 1825) return amount * (isHighValue ? 0.07 : 0.071);    // 3 years < 5 years
      if (days <= 3650) return amount * (isHighValue ? 0.07 : 0.07);     // 5 years to 10 years
  
      return "Invalid tenure";
    },
    savingsDescription: "Uses flat rates: 3% below ₹50L, 3.5% from ₹50L to ₹2000Cr, MIBOR + 0.70% above ₹2000Cr",
    fdDescription: "For <₹3Cr: 7-29d: 3%, 30-45d: 3.5%, 46-60d: 4.25%, 61-90d: 4.5%, 3m-6m: 4.75%, 6m-9m: 5.75%, 9m-1y: 6%, 1y-15m: 6.7%, 15m-2y: 7.25%, >2y: 7.1%. Higher rates for ₹3-5Cr."
  };
  
  const IDFC = {
    savings: (amount) => {
      if (amount <= 500000) return amount * 0.03;
      if (amount <= 50000000) return 500000 * 0.03 + (amount - 500000) * 0.0725;
      if (amount <= 100000000) return 500000 * 0.03 + 49500000 * 0.0725 + (amount - 50000000) * 0.05;
      if (amount <= 200000000) return 500000 * 0.03 + 49500000 * 0.0725 + 50000000 * 0.05 + (amount - 100000000) * 0.045;
      return 500000 * 0.03 + 49500000 * 0.0725 + 50000000 * 0.05 + 100000000 * 0.045 + (amount - 200000000) * 0.035;
    },
    fd: (amount, days) => {
      if (amount >= 30000000) return "Contact Branch";  // 3 Crore or more
  
      if (days < 7) return "Invalid tenure";
      if (days <= 14) return amount * 0.03;            // 7-14 days @ 3.00%
      if (days <= 29) return amount * 0.03;            // 15-29 days @ 3.00%
      if (days <= 45) return amount * 0.03;            // 30-45 days @ 3.00%
      if (days <= 90) return amount * 0.045;           // 46-90 days @ 4.50%
      if (days <= 180) return amount * 0.045;          // 91-180 days @ 4.50%
      if (days <= 364) return amount * 0.0575;         // 181 days to < 1 year @ 5.75%
      if (days === 365) return amount * 0.065;         // 1 year exactly @ 6.50%
      if (days <= 399) return amount * 0.0725;         // 1 year 1 day - 399 days @ 7.25%
      if (days <= 500) return amount * 0.0775;         // 400-500 days @ 7.75%
      if (days <= 730) return amount * 0.0725;         // 501 days - 2 years @ 7.25%
      if (days <= 1095) return amount * 0.068;         // 2 years 1 day - 3 years @ 6.80%
      if (days <= 1825) return amount * 0.0675;        // 3 years 1 day - 5 years @ 6.75%
      if (days <= 3650) return amount * 0.065;         // 5 years 1 day - 10 years @ 6.50%
      return "Invalid tenure";
    },
    savingsDescription: "Uses stepped slabs: 3% up to ₹5L, 7.25% from ₹5L to ₹50Cr, 5% from ₹50-100Cr, 4.5% from ₹100-200Cr, 3.5% above ₹200Cr",
    fdDescription: "7-45d: 3%, 46-180d: 4.5%, 181d-<1y: 5.75%, 1y: 6.5%, 1y1d-399d: 7.25%, 400-500d: 7.75%, 501d-2y: 7.25%, 2y1d-3y: 6.8%, 3y1d-5y: 6.75%, 5y1d-10y: 6.5%"
  };
  
  const ICICI = {
    savings: (amount) => {
      return amount < 5000000 ? amount * 0.03 : amount * 0.035;
    },
    fd: (amount, days) => {
      if (amount >= 30000000) return "Contact Branch";  // 3 Crore or more
  
      if (days < 7) return "Invalid tenure";
      if (days <= 29) return amount * 0.03;            // 7-29 days @ 3.00%
      if (days <= 45) return amount * 0.035;           // 30-45 days @ 3.50%
      if (days <= 60) return amount * 0.0425;          // 46-60 days @ 4.25%
      if (days <= 90) return amount * 0.045;           // 61-90 days @ 4.50%
      if (days <= 184) return amount * 0.0475;         // 91-184 days @ 4.75%
      if (days <= 270) return amount * 0.0575;         // 185-270 days @ 5.75%
      if (days <= 364) return amount * 0.06;           // 271 days to < 1 year @ 6.00%
      if (days <= 455) return amount * 0.067;          // 1 year to < 15 months @ 6.70%
      if (days <= 547) return amount * 0.0725;         // 15 months to < 18 months @ 7.25%
      if (days <= 730) return amount * 0.0725;         // 18 months to 2 years @ 7.25%
      if (days <= 1825) return amount * 0.07;          // 2 years 1 day to 5 years @ 7.00%
      if (days <= 3650) return amount * 0.069;         // 5 years 1 day to 10 years @ 6.90%
  
      // Special case for Tax Saver FD (5 years)
      if (days === 1825) return amount * 0.07;         // 5Y Tax Saver FD @ 7.00%
  
      return "Invalid tenure";
    },
    savingsDescription: "Uses flat rates: 3% below ₹50L, 3.5% above ₹50L",
    fdDescription: "7-29d: 3%, 30-45d: 3.5%, 46-60d: 4.25%, 61-90d: 4.5%, 91-184d: 4.75%, 185-270d: 5.75%, 271d-1y: 6%, 1y-15m: 6.7%, 15m-2y: 7.25%(highest), >2y-5y: 7%, >5y: 6.9%, Tax Saver(5y): 7%"
  };
  
  const HDFC = {
    savings: (amount) => {
      return amount < 5000000 ? amount * 0.03 : amount * 0.035;
    },
    fd: (amount, days) => {
      if (amount >= 30000000) return "Contact Branch";  // 3 Crore or more
  
      if (days < 7) return "Invalid tenure";
      if (days <= 14) return amount * 0.03;            // 7-14 days @ 3.00%
      if (days <= 29) return amount * 0.03;            // 15-29 days @ 3.00%
      if (days <= 45) return amount * 0.035;           // 30-45 days @ 3.50%
      if (days <= 60) return amount * 0.045;           // 46-60 days @ 4.50%
      if (days <= 89) return amount * 0.045;           // 61-89 days @ 4.50%
      if (days <= 180) return amount * 0.045;          // 90 days <= 6 months @ 4.50%
      if (days <= 270) return amount * 0.0575;         // 6 months 1 day <= 9 months @ 5.75%
      if (days <= 364) return amount * 0.06;           // 9 months 1 day to < 1 year @ 6.00%
      if (days <= 455) return amount * 0.066;          // 1 year to < 15 months @ 6.60%
      if (days <= 547) return amount * 0.071;          // 15 months to < 18 months @ 7.10%
      if (days <= 630) return amount * 0.0725;         // 18 months to < 21 months @ 7.25%
      if (days <= 730) return amount * 0.07;           // 21 months - 2 years @ 7.00%
      if (days <= 1050) return amount * 0.07;          // 2 years 1 day to < 2 year 11 months @ 7.00%
      if (days <= 1065) return amount * 0.0735;        // 2 years 11 months - 35 months @ 7.35%
      if (days <= 1095) return amount * 0.07;          // 2 years 11 months 1 day <= 3 years @ 7.00%
      if (days <= 1672) return amount * 0.07;          // 3 years 1 day to < 4 years 7 months @ 7.00%
      if (days <= 1680) return amount * 0.074;         // 4 years 7 months - 55 months @ 7.40%
      if (days <= 1825) return amount * 0.07;          // 4 years 7 months 1 day <= 5 years @ 7.00%
      if (days <= 3650) return amount * 0.07;          // 5 years 1 day - 10 years @ 7.00%
  
      return "Invalid tenure";
    },
    savingsDescription: "Uses flat rates: 3% below ₹50L, 3.5% above ₹50L",
    fdDescription: "7-29d: 3%, 30-45d: 3.5%, 46-180d: 4.5%, 181-270d: 5.75%, 271-364d: 6%, 1y-15m: 6.6%, 15m-18m: 7.1%, 18m-21m: 7.25%(highest), 21m-2y11m: 7%, 35m: 7.35%, >3y: 7%, 55m: 7.4%, >5y: 7%"
  };
  
  const SBI = {
    savings: (amount) => {
      return amount < 100000000 ? amount * 0.027 : amount * 0.03;
    },
    fd: (amount, days) => {
      function calculateBaseRate(days) {
        if (days <= 1825) return 0.0675;  // 3-5 years rate
        return 0.065;                     // >5 years rate
      }
  
      if (amount >= 30000000) return "Contact Branch";  // 3 Crore or more
  
      if (days < 7) return "Invalid tenure";
  
      // Special schemes first
      if (days === 400) return amount * 0.071;         // Amrit Kalash @ 7.10%
      if (days === 444) return amount * 0.0725;        // Amrit Vrishti @ 7.25%
  
      // Green Rupee Term Deposit (10 bps below card rate)
      if (days === 1111) return amount * (calculateBaseRate(1111) - 0.001);
      if (days === 1777) return amount * (calculateBaseRate(1777) - 0.001);
      if (days === 2222) return amount * (calculateBaseRate(2222) - 0.001);
  
      // Regular tenures
      if (days <= 45) return amount * 0.035;           // 7-45 days @ 3.50%
      if (days <= 179) return amount * 0.055;          // 46-179 days @ 5.50%
      if (days <= 210) return amount * 0.0625;         // 180-210 days @ 6.25%
      if (days <= 364) return amount * 0.065;          // 211 days to < 1 year @ 6.50%
      if (days <= 729) return amount * 0.068;          // 1 year to < 2 years @ 6.80%
      if (days <= 1094) return amount * 0.07;          // 2 years to < 3 years @ 7.00%
      if (days <= 1824) return amount * 0.0675;        // 3 years to < 5 years @ 6.75%
      if (days <= 3650) return amount * 0.065;         // 5 years and up to 10 years @ 6.50%
  
      return "Invalid tenure";
    },
    savingsDescription: "Uses flat rates: 2.7% below ₹10Cr, 3% above ₹10Cr",
    fdDescription: "7-45d: 3.5%, 46-179d: 5.5%, 180-210d: 6.25%, 211d-1y: 6.5%, 1y-2y: 6.8%, 2y-3y: 7%, 3y-5y: 6.75%, >5y: 6.5%. Special: Amrit Kalash(400d): 7.1%, Amrit Vrishti(444d): 7.25%. Green deposits available for 1111/1777/2222 days"
  };
  
  const Equitas = {
    savings: (amount) => {
      if (amount <= 100000) return amount * 0.03;  // Up to 1L @ 3%
      if (amount <= 700000) return 100000 * 0.03 + (amount - 100000) * 0.05;  // 1L to 7L @ 5%
      if (amount <= 250000000) return 100000 * 0.03 + 600000 * 0.05 + (amount - 700000) * 0.07;  // 7L to 25Cr @ 7%
      return 100000 * 0.03 + 600000 * 0.05 + 249300000 * 0.07 + (amount - 250000000) * 0.078;  // Above 25Cr @ 7.8%
    },
    fd: (amount, days) => {
      if (amount >= 30000000) return "Contact Branch";  // 3 Crore or more
  
      if (days < 7) return "Invalid tenure";
      if (days <= 14) return amount * 0.035;           // 7-14 days @ 3.50%
      if (days <= 29) return amount * 0.035;           // 15-29 days @ 3.50%
      if (days <= 45) return amount * 0.04;            // 30-45 days @ 4.00%
      if (days <= 62) return amount * 0.045;           // 46-62 days @ 4.50%
      if (days <= 90) return amount * 0.045;           // 63-90 days @ 4.50%
      if (days <= 120) return amount * 0.0525;         // 91-120 days @ 5.25%
      if (days <= 180) return amount * 0.0525;         // 121-180 days @ 5.25%
      if (days <= 210) return amount * 0.0675;         // 181-210 days @ 6.75%
      if (days <= 270) return amount * 0.0675;         // 211-270 days @ 6.75%
      if (days <= 364) return amount * 0.07;           // 271-364 days @ 7.00%
      if (days <= 443) return amount * 0.081;          // 1 year to 443 days @ 8.10%
      if (days === 444) return amount * 0.0825;        // 444 Days exactly @ 8.25%
      if (days <= 547) return amount * 0.081;          // 445 Days to 18 Months @ 8.10%
      if (days <= 730) return amount * 0.0775;         // 18 Months 1 day to 2 years @ 7.75%
      if (days <= 887) return amount * 0.08;           // 2 years 1 day to 887 days @ 8.00%
      if (days === 888) return amount * 0.0815;        // 888 days exactly @ 8.15%
      if (days <= 1095) return amount * 0.08;          // 889 days to 3 years @ 8.00%
      if (days <= 1460) return amount * 0.075;         // 3 years 1 day to 4 years @ 7.50%
      if (days <= 1825) return amount * 0.0725;        // 4 years 1 day to 5 years @ 7.25%
      if (days <= 3650) return amount * 0.0725;        // 5 years 1 day to 10 years @ 7.25%
  
      return "Invalid tenure";
    },
    savingsDescription: "Uses incremental slabs: 3% up to ₹1L, 5% from ₹1-7L, 7% from ₹7L-25Cr, 7.8% above ₹25Cr",
    fdDescription: "Peak rates: 444 days @ 8.25%, 1y-443d & 445d-18m @ 8.1%, 888d @ 8.15%. Other key rates: 7-45d: 3.5-4%, 46-180d: 4.5-5.25%, 181-364d: 6.75-7%, 18m-3y: 7.75-8%, >3y: 7.25-7.5%"
  };
  
  const Federal = {
    savings: (amount) => {
      if (amount <= 100000) return amount * 0.03;  // Up to 1L @ 3%
      if (amount <= 5000000) return 100000 * 0.03 + (amount - 100000) * 0.03;  // Up to 50L @ 3%
      if (amount <= 20000000) return 100000 * 0.03 + (amount - 100000) * 0.0375;  // 50L to 2Cr @ 3.75%
      if (amount <= 50000000) return 100000 * 0.03 + (amount - 100000) * 0.04;  // 2Cr to 5Cr @ 4%
      if (amount <= 500000000) return 100000 * 0.03 + (amount - 100000) * 0.055;  // 5Cr to 50Cr @ 5.5%
      if (amount <= 1500000000) return 100000 * 0.03 + (amount - 100000) * 0.071;  // 50Cr to 150Cr @ 7.1%
      if (amount <= 5000000000) return 100000 * 0.03 + (amount - 100000) * 0.0575;  // 150Cr to 500Cr @ 5.75%
      return 100000 * 0.03 + (amount - 100000) * 0.073;  // Above 500Cr @ 7.3%
    },
    fd: (amount, days) => {
      if (amount >= 30000000) return "Contact Branch";  // 3 Crore or more
  
      if (days < 7) return "Invalid tenure";
      if (days <= 29) return amount * 0.03;            // 7-29 days @ 3.00%
      if (days <= 45) return amount * 0.035;           // 30-45 days @ 3.50%
      if (days <= 180) return amount * 0.055;          // 46-180 days @ 5.50%
      if (days === 181) return amount * 0.065;         // 181 days exactly @ 6.50%
      if (days <= 364) return amount * 0.0625;         // 182 days to < 1 year @ 6.25%
      if (days <= 399) return amount * 0.068;          // 1 year to 399 days @ 6.80%
      if (days === 400) return amount * 0.0735;        // 400 days exactly @ 7.35%
      if (days <= 776) return amount * 0.0705;         // 401 days to 776 days @ 7.05%
      if (days === 777) return amount * 0.074;         // 777 days exactly @ 7.40%
      if (days <= 1094) return amount * 0.0705;        // 778 days to < 3 years @ 7.05%
      if (days <= 1520) return amount * 0.07;          // 3 years to < 50 months @ 7.00%
      if (days === 1521) return amount * 0.074;        // 50 months exactly @ 7.40%
      if (days <= 1825) return amount * 0.07;          // Above 50 months to < 5 years @ 7.00%
      if (days <= 3650) return amount * 0.066;         // 5 years and above @ 6.60%
  
      return "Invalid tenure";
    },
    savingsDescription: "Uses incremental slabs: 3% up to ₹50L, 3.75% from ₹50L-2Cr, 4% from ₹2-5Cr, 5.5% from ₹5-50Cr, 7.1% from ₹50-150Cr, 5.75% from ₹150-500Cr, 7.3% above ₹500Cr",
    fdDescription: "Peak rates: 400d @ 7.35%, 777d @ 7.4%, 50m @ 7.4%. Other rates: 7-45d: 3-3.5%, 46-180d: 5.5%, 181d: 6.5%, 182d-1y: 6.25%, 1y-399d: 6.8%, 401-776d & 778d-3y: 7.05%, 3y-5y: 7%, >5y: 6.6%"
  };
  
  const BOI = {
    savings: (amount) => {
      if (amount <= 100000) return amount * 0.0275;  // Up to 1L @ 2.75%
      if (amount <= 50000000000) return 100000 * 0.0275 + (amount - 100000) * 0.029;  // Up to 500Cr @ 2.9%
      if (amount <= 100000000000) return 100000 * 0.0275 + (amount - 100000) * 0.03;  // Up to 1000Cr @ 3%
      if (amount <= 150000000000) return 100000 * 0.0275 + (amount - 100000) * 0.0305;  // Up to 1500Cr @ 3.05%
      return 100000 * 0.0275 + (amount - 100000) * 0.031;  // Above 1500Cr @ 3.1%
    },
    fd: (amount, days) => {
      if (amount >= 30000000) {
        // Rates for deposits ₹3 Cr to ₹10 Cr
        if (amount < 100000000) {
          if (days < 7) return "Invalid tenure";
          if (days <= 45) return amount * 0.045;           // 7-45 days @ 4.50%
          if (days <= 90) return amount * 0.0525;          // 46-90 days @ 5.25%
          if (days <= 179) return amount * 0.06;           // 91-179 days @ 6.00%
          if (days <= 210) return amount * 0.065;          // 180-210 days @ 6.50%
          if (days <= 364) return amount * 0.0675;         // 211 days to < 1 year @ 6.75%
          if (days === 365) return amount * 0.0725;        // 1 year exactly @ 7.25%
          if (days <= 730) return amount * 0.0675;         // > 1 year to < 2 years @ 6.75%
          if (days <= 1095) return amount * 0.065;         // 2-3 years @ 6.50%
          if (days <= 1824) return amount * 0.06;          // 3-5 years @ 6.00%
          if (days <= 3650) return amount * 0.06;          // 5-10 years @ 6.00%
        }
        return "Contact Branch";  // For ₹10 Cr and above
      }
  
      // Regular rates for deposits less than ₹3 Cr
      if (days < 7) return "Invalid tenure";
      if (days <= 45) return amount * 0.03;            // 7-45 days @ 3.00%
      if (days <= 179) return amount * 0.045;          // 46-179 days @ 4.50%
      if (days <= 269) return amount * 0.06;           // 180-269 days @ 6.00%
      if (days <= 364) return amount * 0.06;           // 270 days to < 1 year @ 6.00%
      if (days === 365) return amount * 0.068;         // 1 year exactly @ 6.80%
      if (days === 400) return amount * 0.073;         // 400 days special rate @ 7.30%
      if (days <= 730) return amount * 0.068;          // > 1 year to < 2 years @ 6.80%
      if (days <= 1095) return amount * 0.0675;        // 2-3 years @ 6.75%
      if (days <= 1824) return amount * 0.065;         // 3-5 years @ 6.50%
      if (days <= 2920) return amount * 0.06;          // 5-8 years @ 6.00%
      if (days <= 3650) return amount * 0.06;          // 8-10 years @ 6.00%
  
      return "Invalid tenure";
    },
    savingsDescription: "Uses incremental slabs: 2.75% up to ₹1L, 2.9% up to ₹500Cr, 3% up to ₹1000Cr, 3.05% up to ₹1500Cr, 3.1% above ₹1500Cr",
    fdDescription: "For <₹3Cr: Peak rate 400d @ 7.3%, Other rates: 7-45d: 3%, 46-179d: 4.5%, 180-364d: 6%, 1y: 6.8%, >1y-2y: 6.8%, 2y-3y: 6.75%, 3y-5y: 6.5%, >5y: 6%. Higher rates for ₹3-10Cr deposits."
  };
  
  const BOB = {
    savings: (amount) => {
      if (amount <= 100000000) return amount * 0.0275;  // Up to 10Cr @ 2.75%
      if (amount <= 500000000) return amount * 0.0275;  // Up to 50Cr @ 2.75%
      if (amount <= 1000000000) return amount * 0.03;  // Up to 100Cr @ 3%
      if (amount <= 2000000000) return amount * 0.03;  // Up to 200Cr @ 3%
      if (amount <= 5000000000) return amount * 0.0305;  // Up to 500Cr @ 3.05%
      if (amount <= 10000000000) return amount * 0.041;  // Up to 1000Cr @ 4.1%
      return amount * 0.045;  // Above 1000Cr @ 4.5%
    },
    fd: (amount, days) => {
      if (amount >= 30000000) return "Contact Branch";  // 3 Crore or more
  
      if (days < 7) return "Invalid tenure";
      if (days <= 14) return amount * 0.0425;          // 7-14 days @ 4.25%
      if (days <= 45) return amount * 0.045;           // 15-45 days @ 4.50%
      if (days <= 90) return amount * 0.055;           // 46-90 days @ 5.50%
      if (days <= 180) return amount * 0.056;          // 91-180 days @ 5.60%
      if (days <= 210) return amount * 0.0575;         // 181-210 days @ 5.75%
      if (days <= 270) return amount * 0.0625;         // 211-270 days @ 6.25%
      if (days <= 364) return amount * 0.065;          // 271 days to < 1 year @ 6.50%
      if (days === 365) return amount * 0.0685;        // 1 year exactly @ 6.85%
      if (days <= 400) return amount * 0.07;           // > 1 year to 400 days @ 7.00%
      if (days <= 730) return amount * 0.07;           // > 400 days to 2 years @ 7.00%
      if (days <= 1095) return amount * 0.0715;        // > 2 years to 3 years @ 7.15%
      if (days <= 1825) return amount * 0.068;         // > 3 years to 5 years @ 6.80%
      if (days <= 3650) return amount * 0.065;         // > 5 years to 10 years @ 6.50%
      if (days > 3650) return amount * 0.0625;         // Above 10 years @ 6.25%
  
      return "Invalid tenure";
    },
    savingsDescription: "Uses flat rates: 2.75% up to ₹50Cr, 3% up to ₹200Cr, 3.05% up to ₹500Cr, 4.1% up to ₹1000Cr, 4.5% above ₹1000Cr",
    fdDescription: "Key rates: 7-14d: 4.25%, 15-45d: 4.5%, 46-90d: 5.5%, 91-180d: 5.6%, 181-210d: 5.75%, 211-270d: 6.25%, 271d-1y: 6.5%, 1y: 6.85%, >1y-2y: 7%, 2y-3y: 7.15%(highest), 3y-5y: 6.8%, >5y-10y: 6.5%, >10y: 6.25%"
  };
  
  const Canara = {
    savings: (amount) => {
      if (amount <= 5000000) return amount * 0.029;  // Up to 50L @ 2.9%
      if (amount <= 50000000) return amount * 0.029;  // Up to 5Cr @ 2.9%
      if (amount <= 100000000) return amount * 0.0295;  // Up to 10Cr @ 2.95%
      if (amount <= 1000000000) return amount * 0.0305;  // Up to 100Cr @ 3.05%
      if (amount <= 2000000000) return amount * 0.035;  // Up to 200Cr @ 3.5%
      if (amount <= 3000000000) return amount * 0.031;  // Up to 300Cr @ 3.1%
      if (amount <= 5000000000) return amount * 0.031;  // Up to 500Cr @ 3.1%
      if (amount <= 10000000000) return amount * 0.034;  // Up to 1000Cr @ 3.4%
      if (amount <= 20000000000) return amount * 0.0355;  // Up to 2000Cr @ 3.55%
      return amount * 0.04;  // Above 2000Cr @ 4%
    },
    fd: (amount, days) => {
      if (amount >= 30000000) return "Contact Branch";  // 3 Crore or more
  
      if (days < 7) return "Invalid tenure";
      if (days <= 45) return amount * 0.04;            // 7-45 days @ 4.00%
      if (days <= 90) return amount * 0.0525;          // 46-90 days @ 5.25%
      if (days <= 179) return amount * 0.055;          // 91-179 days @ 5.50%
      if (days <= 269) return amount * 0.0615;         // 180-269 days @ 6.15%
      if (days <= 364) return amount * 0.0625;         // 270 days to < 1 year @ 6.25%
      if (days === 365) return amount * 0.0685;        // 1 year exactly @ 6.85%
      if (days === 444) return amount * 0.0725;        // 444 days @ 7.25%
      if (days <= 730) return amount * 0.0685;         // Above 1 year to < 2 years @ 6.85%
      if (days <= 1095) return amount * 0.0685;        // 2 years to < 3 years @ 6.85%
      if (days <= 1825) return amount * 0.068;         // 3 years to < 5 years @ 6.80%
      if (days <= 3650) return amount * 0.067;         // 5 years to 10 years @ 6.70%
  
      return "Invalid tenure";
    },
    savingsDescription: "Uses flat rates: 2.9% up to ₹5Cr, 2.95% up to ₹10Cr, 3.05% up to ₹100Cr, 3.5% up to ₹200Cr, 3.1% up to ₹500Cr, 3.4% up to ₹1000Cr, 3.55% up to ₹2000Cr, 4% above",
    fdDescription: "General public callable rates: 7-45d: 4%, 46-90d: 5.25%, 91-179d: 5.5%, 180-269d: 6.15%, 270d-1y: 6.25%, 1y: 6.85%, 444d: 7.25%(highest), >1y-3y: 6.85%, 3y-5y: 6.8%, >5y: 6.7%"
  };
  
  const Bandhan = {
    savings: (amount) => {
      // For balance up to ₹250 crore
      if (amount <= 25000000000) {
        if (amount <= 100000) return amount * 0.03;  // Up to 1L @ 3%
        if (amount <= 1000000) return 100000 * 0.03 + (amount - 100000) * 0.06;  // Up to 10L @ 6%
        if (amount <= 20000000) return 100000 * 0.03 + 900000 * 0.06 + (amount - 1000000) * 0.07;  // Up to 2Cr @ 7%
        if (amount <= 100000000) return 100000 * 0.03 + 900000 * 0.06 + 19000000 * 0.07 + (amount - 20000000) * 0.0625;  // Up to 10Cr @ 6.25%
        if (amount <= 500000000) return 100000 * 0.03 + 900000 * 0.06 + 19000000 * 0.07 + 80000000 * 0.0625 + (amount - 100000000) * 0.065;  // Up to 50Cr @ 6.5%
        if (amount <= 10000000000) return 100000 * 0.03 + 900000 * 0.06 + 19000000 * 0.07 + 80000000 * 0.0625 + 400000000 * 0.065 + (amount - 500000000) * 0.08;  // Up to 100Cr @ 8%
        return 100000 * 0.03 + 900000 * 0.06 + 19000000 * 0.07 + 80000000 * 0.0625 + 400000000 * 0.065 + 9500000000 * 0.08 + (amount - 10000000000) * 0.08;  // Up to 250Cr @ 8%
      }
  
      // For balance above ₹250 crore but below ₹500 crore
      if (amount <= 50000000000) {
        return 100000 * 0.03 + (amount - 100000) * 0.0805;  // Above 250Cr @ 8.05% for balance above 1L
      }
  
      // For balance above ₹500 crore
      return 100000 * 0.03 + (amount - 100000) * 0.0815;  // Above 500Cr @ 8.15% for balance above 1L
    },
    fd: (amount, days) => {
      if (amount >= 30000000) return "Contact Branch";  // 3 Crore or more
  
      if (days < 7) return "Invalid tenure";
      if (days <= 14) return amount * 0.03;            // 7-14 days @ 3.00%
      if (days <= 30) return amount * 0.03;            // 15-30 days @ 3.00%
      if (days <= 60) return amount * 0.035;           // 31 days to < 2 months @ 3.50%
      if (days <= 90) return amount * 0.045;           // 2 months to < 3 months @ 4.50%
      if (days <= 180) return amount * 0.045;          // 3 months to < 6 months @ 4.50%
      if (days <= 364) return amount * 0.045;          // 6 months to < 1 year @ 4.50%
      if (days === 365) return amount * 0.0805;        // 1 year exactly @ 8.05%
      if (days <= 647) return amount * 0.08;           // 1 year 1 day to 1 year 9 months @ 8.00%
      if (days <= 729) return amount * 0.0725;         // 1 year 9 months 1 day to < 2 years @ 7.25%
      if (days <= 1095) return amount * 0.0725;        // 2 years to < 3 years @ 7.25%
      if (days <= 1825) return amount * 0.0725;        // 3 years to < 5 years @ 7.25%
      if (days <= 3650) return amount * 0.0585;        // 5 years to 10 years @ 5.85%
  
      return "Invalid tenure";
    },
    savingsDescription: "Uses incremental slabs: 3% up to ₹1L, 6-8% based on balance tiers up to ₹250Cr, 8.05% above ₹250Cr, 8.15% above ₹500Cr",
    fdDescription: "Key rates: 7-30d: 3%, 31-60d: 3.5%, 2m-1y: 4.5%, 1y: 8.05%(highest), 1y1d-1y9m: 8%, 1y9m1d-5y: 7.25%, 5y-10y: 5.85%. Non-senior rates."
  };
  
  const HSBC = {
    savings: (amount) => {
      return amount < 100000000 ? amount * 0.02 : amount * 0.025;  // Below 10Cr @ 2%, Above @ 2.5%
    },
    fd: (amount, days) => {
      if (amount >= 30000000) return "Contact Branch";  // 3 Crore or more
  
      if (days < 7) return "Invalid tenure";
      if (days <= 7) return amount * 0.0285;           // 7 days @ 2.85%
      if (days <= 8) return amount * 0.0285;           // 8 days @ 2.85%
      if (days <= 14) return amount * 0.03;            // 9-14 days @ 3.00%
      if (days <= 29) return amount * 0.03;            // 15-29 days @ 3.00%
      if (days <= 59) return amount * 0.034;           // 30-59 days @ 3.40%
      if (days <= 89) return amount * 0.036;           // 60-89 days @ 3.60%
      if (days <= 93) return amount * 0.038;           // 90-93 days @ 3.80%
      if (days === 94) return amount * 0.038;          // 94 days @ 3.80%
      if (days <= 179) return amount * 0.04;           // 95-179 days @ 4.00%
      if (days === 180) return amount * 0.045;         // 180 days @ 4.50%
      if (days <= 269) return amount * 0.0325;         // 181-269 days @ 3.25%
      if (days <= 364) return amount * 0.037;          // 270-364 days @ 3.70%
      if (days === 365) return amount * 0.04;          // 365 days @ 4.00%
      if (days <= 399) return amount * 0.04;           // 366-399 days @ 4.00%
      if (days === 400) return amount * 0.0325;        // 400 days @ 3.25%
      if (days <= 547) return amount * 0.0625;         // 401 days to less than 18 months @ 6.25%
      if (days <= 599) return amount * 0.041;          // 18 months to 599 days @ 4.10%
      if (days === 600) return amount * 0.0375;        // 600 days @ 3.75%
      if (days <= 699) return amount * 0.075;          // 601-699 days @ 7.50%
      if (days === 700) return amount * 0.0375;        // 700 days @ 3.75%
      if (days <= 730) return amount * 0.0425;         // 701-730 days @ 4.25%
      if (days === 731) return amount * 0.0425;        // 731 days @ 4.25%
      if (days <= 1095) return amount * 0.065;         // 732 days to less than 36 months @ 6.50%
      if (days <= 1125) return amount * 0.07;          // 36 months to less than 37 months @ 7.00%
      if (days <= 1460) return amount * 0.06;          // 37 months to less than 48 months @ 6.00%
      if (days <= 1825) return amount * 0.06;          // 48 months to 60 months @ 6.00%
  
      return "Invalid tenure";
    },
    savingsDescription: "Uses flat rates: 2% below ₹10Cr, 2.5% above ₹10Cr",
    fdDescription: "Peak rates: 601-699d @ 7.5%, 401d-<18m @ 6.25%, 732d-<36m @ 6.5%, 36m-<37m @ 7%. Other rates: <14d: 2.85-3%, 15-89d: 3-3.6%, 90-179d: 3.8-4%, 180d: 4.5%, 181d-1y: 3.25-4%, >1y varies significantly"
  };
  
  const IDBI = {
    savings: (amount) => {
      // Up to 1L
      if (amount <= 100000) return amount * 0.0275;
      // Above 1L to 5L
      if (amount <= 500000) return 100000 * 0.0275 + (amount - 100000) * 0.029;
      // Above 5L to 5Cr
      if (amount <= 50000000) return 100000 * 0.0275 + 400000 * 0.029 + (amount - 500000) * 0.03;
      // Above 5Cr to 100Cr
      if (amount <= 1000000000) return 100000 * 0.0275 + 400000 * 0.029 + 49500000 * 0.03 + (amount - 50000000) * 0.0325;
      // Above 100Cr to 1000Cr
      if (amount <= 10000000000) return 100000 * 0.0275 + 400000 * 0.029 + 49500000 * 0.03 + 950000000 * 0.0325 + (amount - 1000000000) * 0.035;
      // Above 1000Cr
      const getMIBOR = () => 6.75; // Example MIBOR rate
      if (amount <= 15000000000) return `MIBOR + 0.10% (Rate: ${(getMIBOR() + 0.10).toFixed(2)}%)`;
      if (amount <= 20000000000) return `MIBOR + 0.40% (Rate: ${(getMIBOR() + 0.40).toFixed(2)}%)`;
      return `MIBOR + 0.75% (Rate: ${(getMIBOR() + 0.75).toFixed(2)}%)`;
    },
    fd: (amount, days) => {
      if (amount >= 30000000) return "Contact Branch";  // 3 Crore or more
  
      if (days <= 6) return "Invalid tenure";          // 0-6 days not available
      if (days <= 30) return amount * 0.03;            // 7-30 days @ 3.00%
      if (days <= 45) return amount * 0.0325;          // 31-45 days @ 3.25%
      if (days <= 60) return amount * 0.045;           // 46-60 days @ 4.50%
      if (days <= 90) return amount * 0.0475;          // 61-90 days @ 4.75%
      if (days <= 180) return amount * 0.055;          // 91 days to 6 months @ 5.50%
      if (days <= 270) return amount * 0.06;           // 6 months 1 day to 270 days @ 6.00%
      if (days <= 364) return amount * 0.0625;         // 271 days to < 1 year @ 6.25%
      if (days <= 730) {
        // Special case handling for 375 days, 444 days & 700 days
        if (days === 375 || days === 444 || days === 700) return amount * 0.068;
        return amount * 0.068;                         // 1 year to 2 years @ 6.80%
      }
      if (days <= 1095) return amount * 0.07;          // > 2 years to < 3 years @ 7.00%
      if (days <= 1825) return amount * 0.065;         // 3 years to 5 years @ 6.50%
      if (days <= 3650) return amount * 0.0625;        // > 5 years to 10 years @ 6.25%
      if (days <= 7300) return amount * 0.048;         // > 10 years to 20 years @ 4.80%
  
      return "Invalid tenure";
    },
    savingsDescription: "Uses incremental slabs: 2.75% up to ₹1L, 2.9% up to ₹5L, 3% up to ₹5Cr, 3.25% up to ₹100Cr, 3.5% up to ₹1000Cr, MIBOR linked above",
    fdDescription: "Key rates: 7-45d: 3-3.25%, 46-90d: 4.5-4.75%, 91d-6m: 5.5%, 6m1d-270d: 6%, 271d-1y: 6.25%, 1y-2y: 6.8%, 2y-3y: 7%(highest), 3y-5y: 6.5%, >5y: 6.25%, >10y: 4.8%"
  };
  
  const YesBank = {
    savings: (amount) => {
      if (amount <= 100000) return amount * 0.03;  // Up to 1L @ 3%
      if (amount <= 500000) return 100000 * 0.03 + (amount - 100000) * 0.04;  // Up to 5L @ 4%
      if (amount <= 1000000) return 100000 * 0.03 + 400000 * 0.04 + (amount - 500000) * 0.05;  // Up to 10L @ 5%
      if (amount <= 10000000000) return 100000 * 0.03 + 400000 * 0.04 + 500000 * 0.05 + (amount - 1000000) * 0.07;  // Up to 100Cr @ 7%
      return "Contact Branch";
    },
    fd: (amount, days) => {
      if (amount >= 30000000) return "Contact Branch";  // 3 Crore or more
  
      if (days < 7) return "Invalid tenure";
      if (days <= 14) return amount * 0.0325;          // 7-14 days @ 3.25%
      if (days <= 45) return amount * 0.037;           // 15-45 days @ 3.70%
      if (days <= 90) return amount * 0.041;           // 46-90 days @ 4.10%
      if (days <= 120) return amount * 0.0475;         // 91-120 days @ 4.75%
      if (days <= 180) return amount * 0.05;           // 121-180 days @ 5.00%
      if (days <= 271) return amount * 0.061;          // 181-271 days @ 6.10%
      if (days <= 364) return amount * 0.0635;         // 272 days to < 1 year @ 6.35%
      if (days === 365) return amount * 0.0725;        // 1 year exactly @ 7.25%
      if (days <= 547) return amount * 0.075;          // 1 year 1 day to < 18 months @ 7.50%
      if (days <= 730) return amount * 0.0775;         // 18 months to < 24 months @ 7.75%
      if (days <= 1095) return amount * 0.0725;        // 24 months to < 36 months @ 7.25%
      if (days <= 1825) return amount * 0.0725;        // 36 months to 60 months @ 7.25%
      if (days <= 3650) return amount * 0.07;          // 60 months 1 day to 120 months @ 7.00%
  
      return "Invalid tenure";
    },
    savingsDescription: "Uses incremental slabs: 3% up to ₹1L, 4% from ₹1-5L, 5% from ₹5-10L, 7% from ₹10L-100Cr",
    fdDescription: "Key rates: 7-45d: 3.25-3.7%, 46-180d: 4.1-5%, 181-364d: 6.1-6.35%, 1y: 7.25%, 1y1d-<18m: 7.5%, 18m-<24m: 7.75%(highest), 2y-5y: 7.25%, >5y: 7%"
  };
  
  const Kotak = {
    savings: (amount) => {
      if (amount <= 5000000) return amount * 0.035;  // Up to 50L @ 3.5%
      if (amount <= 10000000000) return amount * 0.04;  // Up to 100Cr @ 4%
      return "Contact Branch";
    },
    fd: (amount, days) => {
      if (amount >= 30000000) return "Contact Branch";  // 3 Crore or more
  
      if (days < 7) return "Invalid tenure";
      if (days <= 14) return amount * 0.0275;          // 7-14 days @ 2.75%
      if (days <= 30) return amount * 0.03;            // 15-30 days @ 3.00%
      if (days <= 45) return amount * 0.0325;          // 31-45 days @ 3.25%
      if (days <= 90) return amount * 0.035;           // 46-90 days @ 3.50%
      if (days <= 120) return amount * 0.04;           // 91-120 days @ 4.00%
      if (days <= 179) return amount * 0.0425;         // 121-179 days @ 4.25%
      if (days === 180) return amount * 0.07;          // 180 days @ 7.00%
      if (days <= 269) return amount * 0.06;           // 181-269 days @ 6.00%
      if (days === 270) return amount * 0.06;          // 270 days @ 6.00%
      if (days <= 363) return amount * 0.06;           // 271-363 days @ 6.00%
      if (days === 364) return amount * 0.065;         // 364 days @ 6.50%
      if (days <= 389) return amount * 0.071;          // 365-389 days @ 7.10%
      if (days === 390) return amount * 0.074;         // 390 days @ 7.40%
      if (days <= 690) return amount * 0.074;          // 391 days to less than 23 months @ 7.40%
      if (days <= 700) return amount * 0.073;          // 23 months @ 7.30%
      if (days <= 730) return amount * 0.073;          // 23 months 1 day to less than 2 years @ 7.30%
      if (days <= 1095) return amount * 0.0715;        // 2 years to less than 3 years @ 7.15%
      if (days <= 1460) return amount * 0.07;          // 3 years to less than 4 years @ 7.00%
      if (days <= 1825) return amount * 0.07;          // 4 years to less than 5 years @ 7.00%
      if (days <= 3650) return amount * 0.062;         // 5 years and above up to 10 years @ 6.20%
  
      return "Invalid tenure";
    },
    savingsDescription: "Uses flat rates: 3.5% up to ₹50L, 4% from ₹50L to ₹100Cr",
    fdDescription: "Peak rates: 390d-<23m @ 7.4%, 180d @ 7%, 365-389d @ 7.1%. Other key rates: <90d: 2.75-3.5%, 91-179d: 4-4.25%, 181d-363d: 6%, 2y-3y: 7.15%, 3y-5y: 7%, >5y: 6.2%"
  };
  
  const CentralBank = {
    savings: (amount) => {
      if (amount <= 1000000000) return amount * 0.028;  // Up to 10Cr @ 2.8%
      if (amount <= 10000000000) return amount * 0.03;  // Up to 100Cr @ 3%
      if (amount <= 100000000000) return amount * 0.031;  // Up to 1000Cr @ 3.1%
      return amount * 0.0325;  // Above 1000Cr @ 3.25%
    },
    fd: (amount, days) => {
      if (amount >= 30000000) return "Contact Branch";  // 3 Crore or more
  
      if (days < 7) return "Invalid tenure";
      if (days <= 14) return amount * 0.035;           // 7-14 days @ 3.50%
      if (days <= 30) return amount * 0.0375;          // 15-30 days @ 3.75%
      if (days <= 45) return amount * 0.0375;          // 31-45 days @ 3.75%
      if (days <= 59) return amount * 0.045;           // 46-59 days @ 4.50%
      if (days <= 90) return amount * 0.0475;          // 60-90 days @ 4.75%
      if (days <= 179) return amount * 0.055;          // 91-179 days @ 5.50%
      if (days <= 270) return amount * 0.0625;         // 180-270 days @ 6.25%
      if (days <= 364) return amount * 0.0625;         // 271-364 days @ 6.25%
      if (days <= 729) return amount * 0.0685;         // 1 year to < 2 years @ 6.85%
      if (days <= 1094) return amount * 0.07;          // 2 years to < 3 years @ 7.00%
      if (days <= 1824) return amount * 0.0675;        // 3 years to < 5 years @ 6.75%
      if (days <= 3650) return amount * 0.065;         // 5 years & above upto 10 years @ 6.50%
  
      return "Invalid tenure";
    },
    savingsDescription: "Uses flat rates: 2.8% up to ₹10Cr, 3% up to ₹100Cr, 3.1% up to ₹1000Cr, 3.25% above",
    fdDescription: "Key rates: 7-45d: 3.5-3.75%, 46-90d: 4.5-4.75%, 91-179d: 5.5%, 180d-1y: 6.25%, 1y-2y: 6.85%, 2y-3y: 7%(highest), 3y-5y: 6.75%, >5y: 6.5%"
  };
  
  const IndianBank = {
    savings: (amount) => {
      if (amount <= 1000000) return amount * 0.0275;  // Up to 10L @ 2.75%
      if (amount <= 20000000000) return amount * 0.028;  // Up to 200Cr @ 2.8%
      return amount * 0.029;  // Above 200Cr @ 2.9%
    },
    fd: (amount, days) => {
      if (amount >= 30000000) return "Contact Branch";  // 3 Crore or more
  
      if (days < 7) return "Invalid tenure";
      if (days <= 14) return amount * 0.028;           // 7-14 days @ 2.80%
      if (days <= 29) return amount * 0.028;           // 15-29 days @ 2.80%
      if (days <= 45) return amount * 0.03;            // 30-45 days @ 3.00%
      if (days <= 90) return amount * 0.0325;          // 46-90 days @ 3.25%
      if (days <= 120) return amount * 0.035;          // 91-120 days @ 3.50%
      if (days <= 180) return amount * 0.0385;         // 121-180 days @ 3.85%
      if (days <= 270) return amount * 0.045;          // 181 days to less than 9 months @ 4.50%
      if (days < 300) return amount * 0.0475;          // 9 months to less than 300 days @ 4.75%
      if (days === 300) return amount * 0.0705;        // 300 days special rate @ 7.05%
      if (days < 365) return amount * 0.0475;          // After 300 days to less than 1 year @ 4.75%
      if (days === 365) return amount * 0.061;         // 1 year exactly @ 6.10%
      if (days === 400) return amount * 0.073;         // 400 days special rate @ 7.30%
      if (days <= 730) return amount * 0.071;          // Above 1 year to less than 2 years @ 7.10%
      if (days <= 1095) return amount * 0.067;         // 2 years to less than 3 years @ 6.70%
      if (days <= 1825) return amount * 0.0625;        // 3 years to 5 years @ 6.25%
      if (days > 1825) return amount * 0.061;          // Above 5 years @ 6.10%
  
      return "Invalid tenure";
    },
    savingsDescription: "Uses flat rates: 2.75% up to ₹10L, 2.8% up to ₹200Cr, 2.9% above",
    fdDescription: "Key rates: Two special products - 300d @ 7.05%, 400d @ 7.3%(highest). Other rates: <180d: 2.8-3.85%, 181d-1y: 4.5-4.75%, 1y: 6.1%, >1y-2y: 7.1%, 2y-3y: 6.7%, >3y: 6.1-6.25%"
  };
  
  const PNB = {
    savings: (amount) => {
      if (amount <= 1000000) return amount * 0.027;  // Up to 10L @ 2.7%
      if (amount <= 10000000000) return amount * 0.0275;  // Up to 100Cr @ 2.75%
      return amount * 0.03;  // Above 100Cr @ 3%
    },
    fd: (amount, days) => {
      if (amount >= 30000000) return "Contact Branch";  // 3 Crore or more
  
      if (days < 7) return "Invalid tenure";
      if (days <= 14) return amount * 0.035;           // 7-14 days @ 3.50%
      if (days <= 29) return amount * 0.035;           // 15-29 days @ 3.50%
      if (days <= 45) return amount * 0.035;           // 30-45 days @ 3.50%
      if (days <= 60) return amount * 0.045;           // 46-60 days @ 4.50%
      if (days <= 90) return amount * 0.045;           // 61-90 days @ 4.50%
      if (days <= 179) return amount * 0.055;          // 91-179 days @ 5.50%
      if (days <= 270) return amount * 0.0625;         // 180-270 days @ 6.25%
      if (days <= 299) return amount * 0.065;          // 271-299 days @ 6.50%
      if (days === 300) return amount * 0.0705;        // 300 days exactly @ 7.05%
      if (days <= 364) return amount * 0.065;          // 301 days to < 1 year @ 6.50%
      if (days === 365) return amount * 0.068;         // 1 year exactly @ 6.80%
      if (days <= 399) return amount * 0.068;          // > 1 year to 399 days @ 6.80%
      if (days === 400) return amount * 0.0725;        // 400 days exactly @ 7.25%
      if (days <= 730) return amount * 0.068;          // 401 days to 2 years @ 6.80%
      if (days <= 1095) return amount * 0.07;          // > 2 years to 3 years @ 7.00%
      if (days <= 1203) return amount * 0.065;         // > 3 years to 1203 days @ 6.50%
      if (days === 1204) return amount * 0.064;        // 1204 days exactly @ 6.40%
      if (days <= 1825) return amount * 0.065;         // 1205 days to 5 years @ 6.50%
      if (days <= 1894) return amount * 0.065;         // > 5 years to 1894 days @ 6.50%
      if (days === 1895) return amount * 0.0635;       // 1895 days exactly @ 6.35%
      if (days <= 3650) return amount * 0.065;         // 1896 days to 10 years @ 6.50%
  
      return "Invalid tenure";
    },
    savingsDescription: "Uses flat rates: 2.7% up to ₹10L, 2.75% up to ₹100Cr, 3% above",
    fdDescription: "Peak rates: 400d @ 7.25%, 300d @ 7.05%. Other key rates: 7-45d: 3.5%, 46-90d: 4.5%, 91-179d: 5.5%, 180-270d: 6.25%, 271d-299d: 6.5%, 1y: 6.8%, 2y-3y: 7%, >3y: 6.35-6.5%"
  };
  
  const UnionBank = {
    savings: (amount) => {
      if (amount <= 5000000) return amount * 0.0275;  // Up to 50L @ 2.75%
      if (amount <= 10000000000) return amount * 0.029;  // Up to 100Cr @ 2.9%
      if (amount <= 50000000000) return amount * 0.031;  // Up to 500Cr @ 3.1%
      if (amount <= 100000000000) return amount * 0.034;  // Up to 1000Cr @ 3.4%
      return amount * 0.0355;  // Above 1000Cr @ 3.55%
    },
    fd: (amount, days) => {
      if (amount >= 20000000) return "Contact Branch";  // 2 Crore or more
  
      if (days < 7) return "Invalid tenure";
      if (days <= 14) return amount * 0.035;           // 7-14 days @ 3.50%
      if (days <= 30) return amount * 0.035;           // 15-30 days @ 3.50%
      if (days <= 45) return amount * 0.035;           // 31-45 days @ 3.50%
      if (days <= 90) return amount * 0.045;           // 46-90 days @ 4.50%
      if (days <= 120) return amount * 0.048;          // 91-120 days @ 4.80%
      if (days <= 180) return amount * 0.049;          // 121-180 days @ 4.90%
      if (days <= 364) return amount * 0.0575;         // 181 days to less than 1 year @ 5.75%
      if (days === 365) return amount * 0.0675;        // 1 year exactly @ 6.75%
      if (days <= 398) return amount * 0.0675;         // More than 1 year to 398 days @ 6.75%
      if (days === 399) return amount * 0.0725;        // 399 days @ 7.25%
      if (days <= 730) return amount * 0.065;          // 400 days to 2 years @ 6.50%
      if (days <= 998) return amount * 0.065;          // More than 2 years to 998 days @ 6.50%
      if (days === 999) return amount * 0.064;         // 999 days @ 6.40%
      if (days <= 1094) return amount * 0.065;         // 1000 days to less than 3 years @ 6.50%
      if (days === 1095) return amount * 0.065;        // 3 years exactly @ 6.50%
      if (days <= 1825) return amount * 0.065;         // More than 3 years to 5 years @ 6.50%
      if (days <= 3650) return amount * 0.065;         // More than 5 years to 10 years @ 6.50%
  
      return "Invalid tenure";
    },
    savingsDescription: "Uses flat rates: 2.75% up to ₹50L, 2.9% up to ₹100Cr, 3.1% up to ₹500Cr, 3.4% up to ₹1000Cr, 3.55% above",
    fdDescription: "Peak rate: 399d @ 7.25%. Other key rates: 7-45d: 3.5%, 46-180d: 4.5-4.9%, 181d-1y: 5.75%, 1y-398d: 6.75%, >400d: 6.5% (with special 999d @ 6.4%)"
  };
  
  const Utkarsh = {
    savings: (amount) => {
      if (amount <= 100000) return amount * 0.04;  // Up to 1L @ 4%
      if (amount <= 500000) return 100000 * 0.04 + (amount - 100000) * 0.0625;  // Up to 5L @ 6.25%
      if (amount <= 5000000000) return 100000 * 0.04 + 400000 * 0.0625 + (amount - 500000) * 0.075;  // Up to 50Cr @ 7.50%
      return 100000 * 0.04 + 400000 * 0.0625 + 4999500000 * 0.075 + (amount - 5000000000) * 0.0775;  // Above 50Cr @ 7.75%
    },
    fd: (amount, days) => {
      if (amount >= 30000000) return "Contact Branch";  // 3 Crore or more
  
      if (days < 7) return "Invalid tenure";
      if (days <= 45) return amount * 0.04;            // 7-45 days @ 4.00%
      if (days <= 90) return amount * 0.0475;          // 46-90 days @ 4.75%
      if (days <= 180) return amount * 0.055;          // 91-180 days @ 5.50%
      if (days <= 364) return amount * 0.065;          // 181-364 days @ 6.50%
      if (days <= 699) return amount * 0.08;           // 365-699 days @ 8.00%
      if (days <= 729) return amount * 0.0825;         // 700 days to < 2 years @ 8.25%
      if (days <= 1095) return amount * 0.085;         // 2-3 years @ 8.50%
      if (days <= 1460) return amount * 0.0825;        // > 3 years to < 4 years @ 8.25%
      if (days <= 1499) return amount * 0.0775;        // 4 years to 1499 days @ 7.75%
      if (days === 1500) return amount * 0.085;        // 1500 days exactly @ 8.50%
      if (days <= 1826) return amount * 0.0775;        // 1501 days to 5 years @ 7.75%
      if (days <= 3650) return amount * 0.0725;        // > 5 years to 10 years @ 7.25%
  
      return "Invalid tenure";
    },
    savingsDescription: "Uses incremental slabs: 4% up to ₹1L, 6.25% from ₹1-5L, 7.5% from ₹5L-50Cr, 7.75% above ₹50Cr",
    fdDescription: "Peak rates: 700d-<2y @ 8.25%, 2y-3y & 1500d @ 8.50%. Other rates: 7-45d: 4%, 46-90d: 4.75%, 91-180d: 5.5%, 181-364d: 6.5%, 365-699d: 8%, >3y-4y: 8.25%, >4y: 7.25-7.75%"
  };

  module.exports = {
    AU, DBS, Equitas, Federal, HSBC, Indusind, Axis, IDFC, ICICI, HDFC, SBI,
  BOI, BOB, Canara, Bandhan, IDBI, YesBank, Kotak, CentralBank, IndianBank, PNB, UnionBank, Utkarsh};