import React, { useState } from 'react';
import investmentData from './investmentData.json'

const InvestmentPage = () => {
  const [openStates, setOpenStates] = useState({});
  const renderValue = (value) => {
    if (typeof value === 'object' && value !== null) {
      if (Array.isArray(value)) {
        return value.map((item, index) => <div key={index}>{renderValue(item)}</div>);
      } else {
        return (
          <ul>
            {Object.entries(value).map(([key, val]) => (
              <li key={key}>
                <strong>{key}:</strong> {renderValue(val)}
              </li>
            ))}
          </ul>
        );
      }
    }
    return String(value);
  };

  const renderDetails = (item) => (
    <div className="investment-details">
      {Object.entries(item).map(([key, value]) => (
        <div key={key}>
          <strong>{key}:</strong> {renderValue(value)}
        </div>
      ))}
    </div>
  );

  const toggleState = (categoryKey, subCategoryKey = null) => {
    setOpenStates(prev => {
      const newState = { ...prev };
      if (subCategoryKey === null) {
        // Toggle category
        newState[categoryKey] = !prev[categoryKey];
      } else {
        // Toggle subcategory
        if (!newState[categoryKey]) {
          newState[categoryKey] = {};
        }
        newState[categoryKey] = {
          ...newState[categoryKey],
          [subCategoryKey]: !prev[categoryKey]?.[subCategoryKey]
        };
      }
      return newState;
    });
  };

  const renderSubCategories = (category, categoryKey) => {
    if (!category.subCategories && typeof category === 'object') {
      return renderDetails(category);
    }
    if (!category.subCategories) return null;
    return (
      <ul>
        {Object.entries(category.subCategories).map(([key, subCategory]) => (
          <li key={key}>
            <button onClick={() => toggleState(categoryKey, key)}>{key}</button>
            {openStates[categoryKey]?.[key] && renderDetails(subCategory)}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className="investment-page">
      <h1>A handy cheatsheet of available investment options</h1>
      <div className="investment-categories">
        {Object.entries(investmentData).map(([key, category]) => (
          <div key={key} className="category">
            <h2>
              <button onClick={() => toggleState(key)}>{key}</button>
            </h2>
            {openStates[key] && renderSubCategories(category, key)}
          </div>
        ))}
      </div>
    </div>
  );
};

export default InvestmentPage;