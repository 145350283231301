import React, { useRef } from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';

const values = [
  {
    name: "Open & Judgment-free",
    description: "Honest and unashamed to show and accept what's real. Not being defensive and having no fear of judgment."
  },
  {
    name: "Contentment",
    description: "Happy and grateful for what you have."
  },
  {
    name: "Persistent",
    description: "Ready and effortful for small daily victories to reach long-term aspirations."
  },
  {
    name: "Respectful",
    description: "Open and respectful regardless of differences."
  },
  {
    name: "Simplicity & Clarity",
    description: "Keeping things simple and clear."
  }
];

const colors = [
  { front: '#00FF00', back: '#FF00FF' },
  { front: '#f0e962', back: '#911091' },
  { front: '#42dced', back: '#FF4500' },
  { front: '#FF4500', back: '#42dced' },
  { front: '#FF00FF', back: '#00FF00' }
];

const ValueItem = ({ value, color }) => {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start end", "end start"]
  });

  const yName = useTransform(scrollYProgress, [0, 1], ['0%', '-30%']);
  const yDescription = useTransform(scrollYProgress, [0, 1], ['50%', '-120%']);
  const opacity = useTransform(scrollYProgress, [0, 0.3, 0.7, 1], [0, 1, 1, 0]);

  return (
    <div ref={ref} style={{ 
      height: '100vh', 
      position: 'relative', 
      overflow: 'hidden', 
      display: 'flex', 
      flexDirection: 'column', 
      justifyContent: 'center', 
      alignItems: 'center' 
    }}>
      <motion.div style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        maxWidth: '1200px',
        textAlign: 'center',
      }}>
        <motion.h2 style={{
          y: yName,
          opacity,
          fontSize: 'clamp(2rem, 8vw, 6rem)',
          fontWeight: '900',
          letterSpacing: '-0.05em',
          color: color.front,
          textShadow: `0 0 5px ${color.front}, 0 0 5px ${color.front}, 0 0 5px ${color.front}`,
          marginBottom: '1rem',
        }} className="value-title">
          {value.name}
        </motion.h2>
        <motion.p style={{
          y: yDescription,
          opacity,
          fontSize: 'clamp(1rem, 3vw, 1.5rem)',
          fontStyle: 'italic',
          textShadow: `0 0 2px ${color.back}, 0 0 4px ${color.back}, 0 0 8px ${color.back}`,
          color: color.back,
        }} className="value-description">
          {value.description}
        </motion.p>
      </motion.div>
    </div>
  );
};

const ValuesPage = () => {
  return (
    <div style={{ 
      minHeight: '100vh', 
      background: `
        linear-gradient(to right, rgba(255,0,0,0.1), transparent 10%, transparent 90%, rgba(0,255,0,0.1)),
        linear-gradient(to bottom, rgba(0,0,255,0.1), transparent 10%, transparent 90%, rgba(255,255,0,0.1)),
        white
      `
    }}>
      <style>
        {`
          @media (max-width: 768px) {
            .value-title {
              text-shadow: 0 0 1px currentColor,  0 0 2.5px currentColor, 0 0 2.5px currentColor !important;
            }
            .value-description {
              text-shadow: 0 0 1px currentColor, 0 0 3px currentColor, 0 0 3px currentColor !important;
            }
          }
        `}
      </style>
      {values.map((value, index) => (
        <ValueItem key={index} value={value} color={colors[index % colors.length]} />
      ))}
    </div>
  );
};

export default ValuesPage;